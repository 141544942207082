<template>
  <div>
    planer
  </div>
</template>

<script>
import setup from './setup'

export default {
  components: {
  },
  setup,
}
</script>

<style lang="scss">
</style>
